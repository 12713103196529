<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
          <div id="alertError"></div>
        </CRow>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Hi Hello 👋</h1>
                <p class="text-muted">Please Log-in</p>
                <CButton color="primary" @click="signIn" :disabled="loading">Login with Google</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { signIn } from '@/utils/auth';
export default {
  name: 'Login',
  data: () => ({
    loading: false,
  }),
  methods: {
    async signIn() {
      try {
        this.loading = true;
        await signIn();
        this.$router.push(this.$route.query?.redirect ?? '/')
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
